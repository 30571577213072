import {getStorage, setStorage} from '@veasel/core/tools';

import * as Sentry from '@sentry/vue';
// In order to improve load experience, initialize some values from local storage.
function initialStateFromLocalStorage() {
  return {
    color_primary: getStorage('system-color-primary') || undefined,
  };
}

// Helper function to filter users by permission
function filterUsersByPermission(users, permission) {
  if (!Array.isArray(users)) {
    return [];
  }

  return users.filter((u) => {
    return u.roles.some((r) => {
      return r.scopes.includes(permission);
    });
  });
}

export default {
  name: 'system',

  namespaced: true,

  state: {
    version: '',
    systemId: '',
    company_name: undefined,
    frn: undefined,
    lei: undefined,
    home_currency: undefined,
    image_logo: undefined,
    image_logo_square: undefined,
    color_secondary: undefined,
    jurisdiction: undefined,
    financial_year_start_day: undefined,
    financial_year_start_month: undefined,
    sso_config: false,
    sso_settings: undefined,
    sso_enabled: undefined,
    feature_flags: undefined,
    running_calculators: [],
    allUsers: false,
    allTeams: false,
    allRoles: false,
    allClients: false,
    bad_gateway: false,
    regdata_key: undefined,
    regdata_username: undefined,
    osca_username: undefined,
    osca_firm_code: undefined,
    osca_key: undefined,
    mfa_setting: undefined,
    use_teams_for_visibility: undefined,
    enums: {},
    entities: false,
    archivedEntities: false,
    environment: '-',
    running_containers: [],
    changelog: undefined,
    globalConfig: false,
    starsClientVersions: undefined,
    starsClientBenchmarks: undefined,

    // Inflate initial values from local storage, if present
    ...initialStateFromLocalStorage(),
  },

  getters: {
    // Users with permission to review reports
    reviewPermissionUsers: (state) => {
      return filterUsersByPermission(state.allUsers.data, 'review_report');
    },

    // Users with permission to sign off reports
    signOffPermissionUsers: (state) => {
      return filterUsersByPermission(state.allUsers.data, 'sign_off_report');
    },

    // Users with permission to submit reports
    submitPermissionUsers: (state) => {
      return filterUsersByPermission(state.allUsers.data, 'submit_report');
    },

    // Users with permission to view reports
    viewReportPermissionUsers: (state) => {
      return filterUsersByPermission(state.allUsers.data, 'view_report');
    },

    // Get a config value for a given key
    getConfig: (state) => (configKey) => {
      return state[configKey];
    },
  },

  mutations: {
    setSystemConfig(state, config) {
      if (!config.feature_flags) {
        state['globalConfig'] = config;
      }
      for (const i in config) {
        state[i] = config[i];

        if (i === 'color_primary') {
          setStorage('system-color-primary', state.color_primary);
        }
      }
    },

    resetSystemConfig(state) {
      state['globalConfig'] = false;
    },

    setEnums(state, config) {
      state.enums[config.additionalInfo] = config.data.map((e) => e.key);
    },

    setAllUsers(state, config) {
      state.allUsers = config;
    },

    resetTeams(state) {
      state.allTeams = false;
    },

    setAllTeams(state, config) {
      state.allTeams = config;
    },

    setAllRoles(state, config) {
      state.allRoles = config;
    },

    setAllClients(state, config) {
      state.allClients = config;
    },

    resetAllUsers(state) {
      state.allUsers = false;
    },

    resetAllClients(state) {
      state.allClients = false;
    },

    setEntities(state, config) {
      state.entities = config;
    },

    setArchivedEntities(state, config) {
      state.archivedEntities = config;
    },

    resetEntities(state) {
      state.entities = false;
      state.archivedEntities = false;
    },

    setBadGateway(state, value) {
      state.bad_gateway = value;
    },

    setRunningCalculators(state, config) {
      state.running_calculators = config;
    },

    setRunningContainers(state, config) {
      state.running_containers = config;
    },

    setVersion(state, value) {
      state.version = value;
    },

    setSystemId(state, value) {
      state.systemId = value;
    },

    setChangelog(state, value) {
      state.changelog = value;
    },

    setStarsClientVersions(state, value) {
      state.starsClientVersions = value;
    },

    setStarsClientBenchmarks(state, value) {
      state.starsClientBenchmarks = value;
    },
  },

  actions: {
    setSystemConfig(context, config) {
      if (config) {
        context.commit('setSystemConfig', config);
        if (!window.location.host.includes('localhost')) {
          Sentry.getClient().getOptions().environment = config.environment;
        }
      }
    },

    resetSystemConfig(context) {
      context.commit('resetSystemConfig');
    },

    setEnums(context, config) {
      if (config) {
        context.commit('setEnums', config);
      }
    },

    setAllUsers(context, config) {
      if (config) {
        context.commit('setAllUsers', config);
      }
    },

    setAllTeams(context, config) {
      if (config) {
        context.commit('setAllTeams', config);
      }
    },

    setEntities(context, config) {
      if (config) {
        // the different endpoints have different response structures
        const data = config.archived
          ? {action: 'setArchivedEntities', data: config.results}
          : {action: 'setEntities', data: config};
        context.commit(data.action, data.data);
      }
    },

    setAllRoles(context, config) {
      if (config) {
        context.commit('setAllRoles', config);
      }
    },

    setAllClients(context, config) {
      if (config) {
        context.commit('setAllClients', config);
      }
    },

    resetAllUsers(context) {
      context.commit('resetAllUsers');
    },

    resetAllClients(context) {
      context.commit('resetAllClients');
    },

    resetEntities(context) {
      context.commit('resetEntities');
      context.commit('resetTeams');
      context.commit('resetAllUsers');
    },

    resetTeams(context) {
      context.commit('resetTeams');
      context.commit('resetAllUsers');
    },

    setBadGateway(context, value) {
      context.commit('setBadGateway', value);
    },

    setFeatureFlags(context, featureFlags) {
      context.commit('setSystemConfig', {feature_flags: featureFlags});
      if (
        Array.isArray(featureFlags) &&
        featureFlags.includes('exception_reporting') &&
        !window.location.host.includes('localhost')
      ) {
        // Also, if we have the feature flag for session recording, we want to enable that here too
        if (featureFlags.includes('exception_reporting_session_replay')) {
          Sentry.addIntegration(
            Sentry.replayIntegration({
              maskAllText: false,
              useCompression: false,
              mask: ['.v-record-mask'],
            })
          );
        }
      }
    },

    setRunningCalculators(context, running_calculators) {
      context.commit('setRunningCalculators', running_calculators);
    },

    setRunningContainers(context, containers) {
      context.commit(
        'setRunningContainers',
        containers.filter((c) => c.state === 'running')
      );
    },

    setVersion(context, value) {
      context.commit('setVersion', value);
    },

    setSystemId(context, value) {
      context.commit('setSystemId', value);
    },

    setChangelog(context, value) {
      context.commit('setChangelog', value);
    },

    setStarsClientVersions(context, value) {
      context.commit('setStarsClientVersions', value);
    },

    setStarsClientBenchmarks(context, value) {
      context.commit('setStarsClientBenchmarks', value);
    },
  },
};
