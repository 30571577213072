import store from '../store';
import {clone, safeGet, http, getStorage, isValidJSON, stripHtml, decodeBufferArray} from '@veasel/core/tools';

function compileUrl(url, data) {
  return url.replace(/(:)\w+/g, (k) => {
    const value = data[k.slice(1)];
    // to avoid replacing localhost:9090 with localhostundefined
    const result = value ?? k;
    delete data[k.slice(1)];
    return result;
  });
}

function optionsFromParams(data, options) {
  return Object.keys(data)
    .filter((key) => key.startsWith('_'))
    .forEach((key) => {
      options[key] = data[key];
      delete data[key];
    });
}

function api(method, endpoint, data = {}, cacheStore = {}, options = {}) {
  const _data = clone(data);

  // add ArrayBuffer 'content' props missing in _data (ex: user picture and company logos upload)
  for (const key in data) {
    if (data[key] && safeGet(data, key + '.content') instanceof ArrayBuffer) {
      _data[key].content = data[key].content;
    }
  }

  // If a cached value exists return it
  if (!mocks_mode && cacheStore.cache) {
    if (cacheStore?.cacheTimeout) {
      // look at the current cache
      const stringAction = String(cacheStore?.action); // this is to avoid actions that are functions
      const currentCache = store._state?.data?.cache?.[stringAction];
      const currentTimestamp = Date.now();
      // check if the current timestamp should invalidate the cache
      if (currentCache?.timestamp && currentTimestamp - currentCache.timestamp <= cacheStore.cacheTimeout) {
        // return the cached result
        return Promise.resolve(cacheStore.cache);
      }
    } else {
      return Promise.resolve(cacheStore.cache);
    }
  }

  // Merge in any extra options which were passed in the data object (keys beginning with '_'). Mutates 'options'.
  optionsFromParams(_data, options);
  const url = compileUrl(endpoint, _data);

  // Handle mocked data for tutorial demo.
  if (mocks_mode && mocks[method + ' ' + url]) {
    // console.log(method + ' ' + url, mocks[method + ' ' + url], mocks);
    return Promise.resolve(mocks[method + ' ' + url]);
  }

  // If a cache was not specified or the result is not yet cached, send the request
  return http(method, url, _data, {Authorization: 'Bearer ' + getStorage('token'), ...options})
    .then((req) => {
      if (options._isFileDownload) {
        downloadFile(req, options._fileName);
      } else if (options._isPlainText) {
        // Return without parsing into JSON
        return Promise.resolve(req.response);
      } else {
        // parse the request response
        return Promise.resolve(JSON.parse(req.response));
      }
    })
    .catch((err) => {
      // catch potential errors
      if (err.status_code === 401) {
        // only logout the user if the error is from the same domain
        const errUrl = err?.url;
        if (errUrl?.startsWith('/')) {
          // User not authenticated, log them out
          store.dispatch('user/logout', true);
        }

        // Return the original 401 error
        return Promise.reject(err);
      } else if (err.status_code === 502) {
        // Bad gateway, system is down temporarily
        store.dispatch('system/setBadGateway', true);

        // Return the 502 error
        return Promise.reject(err);
      } else if (err.status_code === 404) {
        // Resource not found, if required stash null values
        if (cacheStore.storeIfNotFound !== undefined) {
          return Promise.resolve(cacheStore.storeIfNotFound);
        }

        // Otherwise return 404 error as normal
        return Promise.reject(err);
      } else {
        if (!options._suppressNotify) {
          // First check for an error field
          if (err.error) {
            // Parse from array buffer if necessary
            if (err.error instanceof ArrayBuffer) {
              err.error = String.fromCharCode.apply(null, new Uint8Array(err.error));
            }

            // Attempt to parse as JSON
            if (isValidJSON(err.error)) {
              const jsonError = JSON.parse(err.error);
              const errorContent = jsonError.message;
              const errorTitle = '(' + err.status_code + ') ' + jsonError.title;
              Notify.error(errorContent, errorTitle);
            } else {
              // Otherwise strip out HTML and show
              const errorContent = stripHtml(err.error);
              Notify.error(errorContent, err.status_code);
            }
          } else {
            // Last resort, if .error is not specified, show the raw error
            Notify.error(err);
            console.error(err);
          }
        }

        // Pass the error onward to downstream handlers
        if (err.error && err.error instanceof ArrayBuffer) {
          err.error = decodeBufferArray(err.error);
        }
        return Promise.reject(err);
      }
    })
    .then(function (result) {
      const cachedResult =
        typeof result === 'object' && !Array.isArray(result)
          ? {...result, additionalInfo: cacheStore.additionalInfo}
          : result;

      if (cacheStore?.cacheTimeout) {
        const stringAction = String(cacheStore?.action); // this is to avoid actions that are functions
        const currentCache = store._state?.data?.cache?.[stringAction];
        const currentTimestamp = Date.now();
        // check if the current timestamp should invalidate the cache
        if (!currentCache?.timestamp || currentTimestamp - currentCache.timestamp > cacheStore.cacheTimeout) {
          // return the cached result
          store.dispatch('cache/setCache', {key: stringAction});
        }
      }
      // cache the result in vuex store if needed, and return it
      if (typeof cacheStore.action === 'string') {
        store.dispatch(cacheStore.action, cachedResult);
      } else if (typeof cacheStore.action === 'function') {
        cacheStore.action(result);
      }
      return Promise.resolve(result);
    });
}

function downloadFile(request, fileName) {
  // Create an invisible 'a' element
  const a = document.createElement('a');
  a.style.display = 'none';
  document.body.appendChild(a);

  // Set the HREF to a Blob representation of the data to be downloaded
  a.href = window.URL.createObjectURL(
    new Blob([request.response.data ? request.response.data : request.response], {
      type: request.getResponseHeader('Content-Type'),
    })
  );

  // if not set, use file name sent by the backend
  let downloadFileName =
    fileName || (request.getResponseHeader('Content-Disposition') || 'filename=').split('filename=')[1];

  // Remove double quotes to avoid underscores before and after filename
  downloadFileName = downloadFileName ? downloadFileName.replace(/"/g, '') : '';

  // Use download attribute to set set desired file name
  a.setAttribute('download', downloadFileName);

  // Trigger the download by simulating click
  a.click();

  // Cleanup
  window.URL.revokeObjectURL(a.href);
  document.body.removeChild(a);
}

let mocks_mode = false;
let mocks = {};

export default {
  _enableMocks(data) {
    mocks_mode = true;
    mocks = data;
  },

  _disableMocks() {
    mocks_mode = false;
    mocks = {};
  },

  _getMocks() {
    return mocks;
  },

  // BATCHES

  getBatches: function (params) {
    return api('GET', '/api/data/batch', params);
  },

  getBatchesSummary: function (params) {
    return api('GET', '/api/data/batch/summary', params);
  },

  // CONDITIONS

  getAllConditions: function () {
    return api('GET', '/api/conditions', {}, {cache: store.state.logic.requirements, action: 'logic/setRequirements'});
  },

  getCondition: function (params) {
    return api('GET', '/api/conditions/:condition_name', params);
  },

  // CONFIG

  getSystemConfig: function () {
    return api(
      'GET',
      '/api/config',
      {},
      {cache: store.state.system.globalConfig, action: 'system/setSystemConfig'},
      {timeout: 12000}
    );
  },

  updateSystemLogo: function (params) {
    return api(
      'PUT',
      '/api/config/file/:config_key',
      params,
      {action: 'system/setSystemConfig'},
      {_isFileUpload: true}
    );
  },

  resetSystemLogo: function (params) {
    return api('DELETE', '/api/config/file/:config_key', params, {action: 'system/setSystemConfig'});
  },

  updateRegulatorConfig: function (params) {
    return api('PUT', '/api/config/regulator-config', params);
  },

  updateSystemConfig: function (params) {
    return api('PUT', '/api/config/value/:config_key', params, {action: 'system/setSystemConfig'});
  },

  getSystemConfigByKey: function (params) {
    const config_key = params.config_key;

    return api('GET', '/api/config/:config_key', params, {
      action: (result) => store.dispatch('system/setSystemConfig', {[config_key]: result}),
      storeIfNotFound: null,
    });
  },

  // COUNTRIES

  getCountries: function () {
    return api('GET', '/api/country', {}, {cache: store.state.data.countries, action: 'data/setCountries'});
  },

  // CURRENCY

  getCurrencies: function () {
    return api('GET', '/api/currency', {}, {cache: store.state.data.currencies, action: 'data/setCurrencies'});
  },

  getCurrencyExponents: function () {
    return api(
      'GET',
      '/api/currency',
      {},
      {cache: store.state.data.currenciesExponents, action: 'data/setCurrenciesExponents'}
    );
  },

  // DATA

  exportBatchData: function (params) {
    return api('POST', '/api/data/batch/export/:format', params);
  },

  exportBatchDataFiltered: function (params) {
    return api('POST', '/api/data/batch/export/filtered/:format', params);
  },

  getBatchMetadata: function (params) {
    return api('GET', '/api/data/batch/:batch_id', params);
  },

  getDataConfig: function (params) {
    return api('GET', '/api/data/defaults', params);
  },

  saveDataConfig: function (params) {
    return api('PUT', '/api/data/defaults/:report_type', params);
  },

  resetDataConfig: function (params) {
    return api('DELETE', '/api/data/defaults/:report_type/:key', params);
  },

  queryDataBatch: function (params) {
    return api('POST', '/api/data/:data_type/query', params);
  },

  archiveDataBatches: function (params) {
    return api('POST', '/api/data/batch/archive', params);
  },

  getDataFormats: function () {
    return api(
      'GET',
      '/api/data/export/formats',
      {},
      {cache: store.state.data.exportFormats, action: 'data/setExportFormats'}
    );
  },

  getDataTypes: function () {
    return api('GET', '/api/data/types');
  },

  getDataAttributes: function () {
    return api('GET', '/api/data/types/attributes', {}, {action: 'data/setSchemas'});
  },

  getFlatDataAttributes: function () {
    return api('GET', '/api/data/types/attributes/flat', {}, {action: 'data/setFlatSchemas'});
  },

  getData: function (params) {
    return api('GET', '/api/data/:data_type', params);
  },

  queryDataDrillUp: function (params) {
    return api('POST', '/api/data/:data_type/drill_up', params);
  },

  runWhatIfCalculation: (params) => {
    return api('GET', '/api/data/what_if', params);
  },

  ingestData: function (params) {
    return api('POST', '/api/data/:data_type', params);
  },

  uploadData: function (params, options = {}) {
    return api('POST', '/api/data/:data_type/upload', params, {}, options);
  },

  getDetailedData: function (params) {
    return api('GET', '/api/data/:data_type/detailed', params);
  },

  dataValidation: function (params) {
    return api('POST', '/api/data/:data_type/validation', params);
  },

  ingestReportTemplate: function (params) {
    return api(
      'POST',
      '/api/data/:report_type/upload_template?name=:name&currency=:currency&date=:date:logicVersionQuery',
      params,
      {},
      {_isFileUpload: true}
    );
  },

  getDataViewByDataType: function (params) {
    return api('GET', '/api/data/:data_type/view', params);
  },

  queryDataView: function (params) {
    return api('POST', '/api/data/:data_type/view/query', params);
  },

  getViewDataQuality: function (params) {
    return api('GET', '/api/data/view/dataquality', params);
  },

  getViewMetadata: function (params) {
    return api('GET', '/api/data/view/meta', params);
  },

  // DATA COLLECTION

  createDataCollection: function (params) {
    return api('POST', '/api/data-collection', params);
  },

  getDataCollection: function (params) {
    return api('GET', '/api/data-collection', params);
  },

  getDataCollectionById: function (params) {
    return api('GET', '/api/data-collection/:collection_id', params);
  },

  // DATA CONFIGURATION

  getAllDataConfigurations: function (params) {
    return api('GET', '/api/data_configuration', params);
  },

  // DATA MAGNET

  getDataMagnets: function (params) {
    return api('GET', '/api/data_magnet/v2', params);
  },

  getDataMagnet: function (params) {
    return api('GET', '/api/data_magnet/:id', params);
  },

  updateDataMagnet: function (params) {
    return api('PUT', '/api/data_magnet/:id', params);
  },

  createDataMagnet: function (params) {
    return api('POST', '/api/data_magnet', params);
  },

  archiveDataMagnet: function (params) {
    return api('DELETE', '/api/data_magnet/:id', params);
  },

  updateDataMagnetApproval: function (params) {
    return api('PUT', '/api/data_magnet/:magnet_id/approvals/:approval_id', params);
  },

  getDataMagnetEvents: function (params) {
    return api('GET', '/api/data_magnet/:id/events', params);
  },

  addDataMagnetComment: function (params) {
    return api('POST', '/api/data_magnet/:id/comments', params);
  },

  getDataMagnetInfo: function (params) {
    return api('GET', '/api/data_magnet/info/:data_magnet_property', params);
  },

  // ENTITY

  getEntities: function (params) {
    return api('GET', '/api/entity', params);
  },

  getEntitiesSummary: function (params) {
    return api('GET', '/api/summary/entity', params, {
      cache: store.state.system.entities,
      action: 'system/setEntities',
    });
  },

  createEntity: function (params) {
    return api('POST', '/api/entity', params, {action: 'system/resetEntities'});
  },

  updateEntity: function (params) {
    return api('PUT', '/api/entity/:id', params, {action: 'system/resetEntities'});
  },

  deleteEntity: function (params) {
    return api('DELETE', '/api/entity/:id', params, {action: 'system/resetEntities'});
  },

  getEntityClients: function (params) {
    return api('GET', '/api/entity/:id/clients', params);
  },

  updateEntityClients: function (params) {
    return api('PUT', '/api/entity/:id/clients', params, {action: 'system/resetEntities'});
  },

  getEntityConfig: function (params) {
    return api('GET', '/api/entity/:id/config', params);
  },

  updateEntityConfig: function (params) {
    return api('PUT', '/api/entity/:id/config', params, {action: 'system/resetEntities'});
  },

  getEntityUsers: function (params) {
    return api('GET', '/api/entity/:id/users', params);
  },

  updateEntityUsers: function (params) {
    return api('PUT', '/api/entity/:id/users', params, {action: 'system/resetEntities'});
  },

  // ENUM

  getEnum: function (enum_name) {
    return api(
      'GET',
      '/api/enum/' + enum_name,
      {},
      {cache: store.state.system.enums[enum_name], action: 'system/setEnums', additionalInfo: enum_name}
    );
  },

  // EVENTS

  getEvents: function (params) {
    return api('GET', '/api/events', params);
  },

  getEventCategories: function () {
    return api('GET', '/api/events/categories');
  },

  // EXPORT

  createExcelFile: function (params) {
    return api('POST', '/api/export/excel', params, {}, {_isFileDownload: true, _fileName: params.name});
  },

  // FEATURE FLAGS

  getFeatureFlags: function () {
    return api(
      'GET',
      '/api/feature-flags',
      {},
      {cache: store.state.system.feature_flags, action: 'system/setFeatureFlags'}
    );
  },

  // FILE

  getStaticFile: function (params) {
    return api('GET', '/api/file/:id', params, {}, {_isFileDownload: true, _fileName: params.name});
  },

  // FIRM TYPES

  getFirmTypes: function () {
    return api('GET', '/api/firm-types');
  },

  // FOREMAN

  getJobs: function (params) {
    return api('GET', '/api/foreman/job/v2', params);
  },

  getJobById: function (params) {
    return api('GET', '/api/foreman/job/:job_id', params);
  },

  getJobsInfo: function (params) {
    return api('GET', '/api/foreman/job/info/:job_property', params);
  },

  cancelJob: function (params) {
    return api('POST', '/api/foreman/job/:id/cancel', params);
  },

  // INGESTIONS

  getIngestions: function (params) {
    return api('GET', '/api/ingestor/ingestion/v2', params);
  },

  getIngestionDetail: function (params) {
    return api('GET', '/api/ingestor/ingestion/:id', params);
  },

  getIngestionInfo: function (params) {
    return api('GET', '/api/ingestor/ingestion/info/:ingestion_property', params);
  },

  // LOGS

  getLogsSearch: function (params) {
    return api('POST', '/api/logs/search', params);
  },

  getLogContext: function (params) {
    return api('GET', '/api/logs/search', params);
  },

  exportLogs: function (params) {
    return api('POST', '/api/logs/search/export', params, {}, {_isFileDownload: true});
  },

  getLogs: function (params) {
    return api('GET', '/api/logs/:count', params);
  },

  // METAREPORTS

  getMetareports: function (params) {
    return api('GET', '/api/metareport/v2', params);
  },

  suggestMetaReportTags: function (params) {
    return api('POST', '/api/metareport/tag_suggestions', params);
  },

  getLatestMetareport: function (params) {
    return api('GET', '/api/metareport/latest/:metareport_root_id', params);
  },

  archiveMetareports: function (params) {
    return api('DELETE', '/api/metareport/:metareport_id', params);
  },

  unarchiveMetareports: function (params) {
    return api('POST', '/api/metareport/:metareport_id/unarchive', params);
  },

  getMetareport: function (params) {
    return api('GET', '/api/metareport/:metareport_id', params);
  },

  createMetaReport: function (params) {
    return api('POST', '/api/metareport', params);
  },

  updateMetaReport: function (params) {
    return api('PUT', '/api/metareport/:report_id', params);
  },

  publishMetaReport: function (params) {
    return api('PUT', '/api/metareport/:metareport_id/enabled', params);
  },

  persistMetaReportInFileSystem: function (params) {
    return api('POST', '/api/metareport/:report_id/persist', params);
  },

  getMetaReportInfo: function (params) {
    return api('GET', '/api/metareport/info/:metareport_property', params);
  },

  getMetareportCodeSkeleton: function (params) {
    return api('POST', '/api/metareport/python_representation', params, {}, {_isFileDownload: true});
  },

  // METRICS

  getAllMetrics: function () {
    return api('GET', '/api/metrics', {}, {cache: store.state.logic.metrics, action: 'logic/setMetrics'});
  },

  getMetric: function (params) {
    return api('GET', '/api/metrics/:metric_name', params);
  },

  // MFA

  getMFACurrent: function () {
    return api('GET', '/api/mfa/config/current');
  },

  configureMFACurrent: function (params) {
    return api('POST', '/api/mfa/config/current', params);
  },

  disableMFACurrent: function () {
    return api('DELETE', '/api/mfa/config/current');
  },

  configureMFASystem: function (params) {
    return api('PUT', '/api/mfa/config/system', params, {action: 'system/setSystemConfig'});
  },

  disableMFA: function (params) {
    return api('DELETE', '/api/mfa/config/:user_id', params);
  },

  // OAUTH

  getClients: function (params) {
    return api('GET', '/api/oauth/client', params, params.limit === 0 && {action: 'system/setAllClients'});
  },

  createClient: function (params) {
    return api('POST', '/api/oauth/client', params, {action: 'system/resetAllClients'});
  },

  deleteClient: function (params) {
    return api('DELETE', '/api/oauth/client/:id', params, {action: 'system/resetAllClients'});
  },

  updateClient: function (params) {
    return api('PUT', '/api/oauth/client/:id', params, {action: 'system/resetAllClients'});
  },

  getPermissions: function () {
    return api('GET', '/api/oauth/scopes');
  },

  getUserPermissions: function () {
    return api('GET', '/api/api_token/scopes');
  },

  // Use basic http for token requests
  getToken: function (params) {
    return http('POST', '/api/oauth/token', params);
  },

  revokeToken: function (params) {
    return api('POST', '/api/oauth/revoke', params);
  },

  // PRODUCT EXAMPLE

  getProductExamples: function (params) {
    return api('GET', '/api/product-example', params);
  },

  getProductExamplesInfo: function (params) {
    return api('GET', '/api/product-example/info/:product_example_property', params);
  },

  getProductExampleDetail: function (params) {
    return api('GET', '/api/product-example/details/:product_id', params);
  },

  // REPORT

  getReports: function (params) {
    return api('GET', '/api/report/v2', params);
  },

  generateReport: function (params) {
    return api('POST', '/api/report', params);
  },

  getReportsSummary: function (params) {
    return api('GET', '/api/report/summary', params);
  },

  archiveReports: function (params) {
    return api('POST', '/api/report/archive', params);
  },

  unarchiveReports: function (params) {
    return api('POST', '/api/report/unarchive', params);
  },

  submitReports: function (params) {
    return api('POST', '/api/report/submit', params);
  },

  getReportWorkflowDefault() {
    return api('GET', '/api/report/workflow/default');
  },

  getReportWorkflows(params) {
    return api(
      'GET',
      '/api/report/workflows',
      params,
      params.limit === 0 ? {cache: store.state.report.workflows, action: 'report/setReportWorkflows'} : undefined
    );
  },

  createReportWorkflow(params) {
    return api('POST', '/api/report/workflow', params, {action: 'report/resetWorkflows'});
  },

  updateReportWorkflow(params) {
    return api('PUT', '/api/report/workflow/:id', params, {action: 'report/resetWorkflows'});
  },

  checkReportWorkflow(params) {
    return api('PUT', '/api/report/workflow/:id/check', params);
  },

  deleteReportWorkflow(params) {
    return api('DELETE', '/api/report/workflow/:id', params, {action: 'report/resetWorkflows'});
  },

  getReportDetail: function (params) {
    return api('GET', '/api/report/:id', params);
  },

  getReportEvents: function (params) {
    return api('GET', '/api/report/:report_id/events', params);
  },

  getReportCells: function (params) {
    return api('GET', '/api/report/:report_id/cells', params);
  },

  getReportCoverage: function (params) {
    return api('GET', '/api/report/:report_id/coverage', params);
  },

  getDataQuality: function (params) {
    return api('GET', '/api/report/:report_id/dataquality', params);
  },

  getDataQualityInfo: function (params) {
    return api('GET', '/api/report/:report_id/unpaginated_dq_rules', params, {
      cache: store.state.report.dq[params.report_id],
      action: (result) => store.dispatch('report/setReportDQ', {report_id: params.report_id, rules: result}),
    });
  },

  updateReport: function (params) {
    return api('PUT', '/api/report/:report_id', params);
  },

  exportReport: function (params) {
    return api('GET', '/api/report/:report_id/export/:export_format', params, {}, {_isFileDownload: !params.async_job});
  },

  updateReportApproval: function (params) {
    return api('PUT', '/api/report/:report_id/approvals/:approval_id', params);
  },

  toggleReportApproval: function (params) {
    return api('POST', '/api/report/:report_id/set-approval/:step_id/:status', params);
  },

  validateReport: function (params) {
    return api('POST', '/api/report/:report_id/validate', {...params, async_job: true});
  },

  getReportValidation: function (params) {
    return api('GET', '/api/report/:report_id/validation/:validation_id', params);
  },

  exportReportValidation: function (params) {
    return api('POST', '/api/report/:report_id/validate/export/:format', params);
  },

  getReportReconciliation: function (params) {
    return api('GET', '/api/report/:report_id/reconciliation/:reconciliation_id', params);
  },

  exportReportReconciliations: function (params) {
    return api('POST', '/api/report/:report_id/reconcile/export/:format', params, {}, {_isFileDownload: true});
  },

  getReportValidationResult: function (params) {
    return api('GET', '/api/report/:report_id/validation', params);
  },

  updateReportVisibility: function (params) {
    return api('PUT', '/api/report/:report_id/visibility', params);
  },

  getReportTemplate: function (params) {
    const templateKey =
      params.report_type && params.report_format
        ? [params.report_type, params.report_format, params.page].filter((e) => e !== undefined).join('.')
        : '';

    return api(
      'GET',
      '/api/report/:report_id/template/:report_format',
      params,
      {
        cache: store.state.report.reportTemplates[templateKey],
        action: (result) => store.dispatch('report/setReportTemplate', {key: templateKey, template: result}),
      },
      {_isPlainText: true}
    );
  },

  getReportPage: function (params) {
    return api('GET', '/api/report/:report_id/page/:page_id', params);
  },

  reconcileReport: function (params) {
    return api('POST', '/api/report/:report_id/reconcile', params);
  },

  getReportCellMetadata: function (params) {
    const locator = params.locator;
    return api('GET', '/api/report/:report_id/cells/:locator', params, {
      cache: store.state.report.cellMetadata[locator],
      action: (result) => store.dispatch('report/setCellMetadata', {locator: locator, metadata: result}),
    });
  },

  updateReportData: function (params) {
    return api('PUT', '/api/report/:report_id/data', params);
  },

  getReportData: function (params) {
    return api('GET', '/api/report/:report_id/data', params);
  },

  updatePartialReportData: function (params) {
    return api('PATCH', '/api/report/:report_id/data', params);
  },

  addReportComment: function (params) {
    return api('POST', '/api/report/:report_id/comments', params);
  },

  updateReportCollections: function (params) {
    return api('PUT', '/api/report/:report_id/report-collections', params);
  },

  refreshAdjustments: function (params) {
    return api('GET', '/api/report/:report_id/refresh-adjustments', params);
  },

  applyAdjustments: function (params) {
    return api('POST', '/api/report/:report_id/apply-adjustments', params);
  },

  updateReportKwargs: function (params) {
    return api('PUT', '/api/report/:report_id/calculator_kwargs', params);
  },

  recalculateReportData(params) {
    return api('POST', '/api/report/:report_id/recalculate', params);
  },

  getReportTags: function (params) {
    return api('GET', '/api/report-tag', params);
  },

  // REPORT COLLECTIONS

  getReportCollections: function (params) {
    return api('GET', '/api/report-collection/v2', params);
  },

  updateReportCollection: function (params) {
    return api('PUT', '/api/report-collection/:collection_id', params);
  },

  createReportCollection(params) {
    return api('POST', '/api/report-collection', params);
  },

  archiveReportCollection: function (params) {
    return api('DELETE', '/api/report-collection/:collection_id', params);
  },

  getReportCollection: function (params) {
    return api('GET', '/api/report-collection/:collection_id', params);
  },

  addReportCollectionComment: function (params) {
    return api('POST', '/api/report-collection/:collection_id/comments', params);
  },

  getReportCollectionEvents: function (params) {
    return api('GET', '/api/report-collection/:collection_id/events', params);
  },

  getReportCollectionsInfo: function (params) {
    return api('GET', '/api/report-collection/info/:report_collection_property', params);
  },

  validateCollection: function (params) {
    return api('POST', '/api/report-collection/:collection_id/validate', {...params, async_job: true});
  },

  getReportCollectionValidationResults: function (params) {
    return api('GET', '/api/report-collection/:collection_id/validation_results', params);
  },

  exportReportCollectionValidation: function (params) {
    return api('POST', '/api/report-collection/:collection_id/validate/export/:format', params);
  },

  // REPORT CUSTOM VALIDATION RULE
  getAllCustomValidationRules: function (params) {
    return api('GET', '/api/report-custom-validation-rule', params);
  },

  createCustomValidationRule: function (params) {
    return api('POST', '/api/report-custom-validation-rule', params);
  },

  getCustomValidationRule: function (params) {
    return api('GET', '/api/report-custom-validation-rule/:rule_id', params);
  },

  editCustomValidationRule: function (params) {
    return api('PUT', '/api/report-custom-validation-rule/:rule_id', params);
  },

  patchCustomValidationRule: function (params) {
    return api('PATCH', '/api/report-custom-validation-rule/:rule_id', params);
  },

  getCustomValidationRuleComments: function (params) {
    return api('GET', '/api/report-custom-validation-rule/:rule_id/comments', params);
  },

  addCustomValidationRuleComment: function (params) {
    return api('POST', '/api/report-custom-validation-rule/:rule_id/comments', params);
  },

  getCustomValidationRuleEvents: function (params) {
    return api('GET', '/api/report-custom-validation-rule/:rule_id/events', params);
  },

  getCustomValidationInfo: function (params) {
    return api('GET', '/api/report-custom-validation-rule/info/:validation_rule_property', params);
  },

  archiveCustomValidationRule: function (params) {
    return api('DELETE', '/api/report-custom-validation-rule/:rule_id', params);
  },

  unarchiveCustomValidationRule: function (params) {
    return api('PUT', '/api/report-custom-validation-rule/:rule_id', params);
  },

  getCustomValidationReportTypes(params) {
    return api('GET', '/api/report-custom-validation-rule/compatible_report_types', params, {
      cache: store.state.report.customValidationReportTypes,
      action: 'report/setCustomValidationReportTypes',
    });
  },

  putCustomValidationApproval(params) {
    return api('PUT', '/api/report-custom-validation-rule/:rule_id/approvals/:approval_id', params);
  },

  // REPORT DATE RANGE

  getReportDateRanges() {
    return api('GET', '/api/report-date-range');
  },

  createReportDateRange(body) {
    return api('POST', '/api/report-date-range', body);
  },

  deleteReportDateRange(params) {
    return api('DELETE', '/api/report-date-range/:id', params);
  },

  // REPORT-SCHEDULE

  getReportConfs: function (params) {
    return api('GET', '/api/report-schedule', params);
  },

  getAllReportConfs: function (params) {
    return api('GET', '/api/report-schedule', params, {
      cache: store.state.report.reportConfs,
      action: 'report/setReportConfs',
    });
  },

  saveReportConf: function (params) {
    return api('POST', '/api/report-schedule', params, {action: 'report/resetReportConfs'});
  },

  getReportConfById: function (params) {
    return api('GET', '/api/report-schedule/:id', params);
  },

  updateReportConf: function (params) {
    return api('PUT', '/api/report-schedule/:id', params);
  },

  deleteReportConf: function (params) {
    return api('DELETE', '/api/report-schedule/:id', params);
  },

  runReportConf: function (params) {
    return api('POST', '/api/report-schedule/run', params);
  },

  runReportConfById: function (params) {
    return api('POST', '/api/report-schedule/run/:id', params);
  },

  // REPORT-SUBMISSIONS
  getRegulatoryConfig: function (params) {
    return api('GET', '/api/regulator', params);
  },

  createRegulatoryConfig: function (params) {
    return api('POST', '/api/regulator', params);
  },

  updateRegulatoryConfig: function (params) {
    return api('PUT', '/api/regulator/:regulator_id', params);
  },

  getReportSubmissionDates: function (params) {
    return api('GET', '/api/regulator/data-item-dates/:report_type', params);
  },

  getRegulatoryDataItems: function (params) {
    return api('GET', '/api/regulator/data-items/:report_type', params);
  },

  regulatoryOscaCrossvalidateReport: function (params) {
    return api('GET', '/api/regulator/osca-cross-validate', params);
  },

  getReportSubmissionSchedules: function (params) {
    return api('GET', '/api/regulator/schedules', params);
  },

  getReportSubmissions: function (params) {
    return api('GET', '/api/regulator/submissions/v2', params);
  },

  submitReport: function (params) {
    return api('POST', '/api/regulator/submit-report', params);
  },

  updateRegulatorySchedule: function (params) {
    return api('GET', '/api/regulator/update-schedules', params);
  },

  updateRegulatorySubmission: function (params) {
    return api('GET', '/api/regulator/update-submission/:submission_id', params);
  },

  getSubmissionsInfo: function (params) {
    return api('GET', '/api/regulator/submissions/info/:submissions_property', params);
  },

  // REPORT-TYPES

  getReportTypes: function () {
    return api('GET', '/api/report-type', {}, {cache: store.state.report.reportTypes, action: 'report/setReportTypes'});
  },

  getReportType: function (type) {
    return api(
      'GET',
      '/api/report-type/' + type,
      {},
      {cache: store.state.report.reportTypeVersions[type], action: 'report/setReportTypeVersion', additionalInfo: type}
    );
  },

  getSubreports: function () {
    return api(
      'GET',
      '/api/report-type/subreports',
      {},
      {cache: store.state.report.subreports, action: 'report/setSubreports'}
    );
  },

  getReportTypeDocumentation: function (type) {
    return api('GET', '/api/report-type/' + type + '/documentation');
  },

  getReportTypeSource: function (type) {
    return api('GET', '/api/report-type/' + type + '/source');
  },

  mergeReport: function (params) {
    return api('POST', '/api/report-type/:report_type/merge', params);
  },

  getNodes: function (params, options = {}) {
    return api('GET', '/api/report-type/:report_type/nodes', params, {}, options);
  },

  getIdentifiers: function (params) {
    return api('GET', '/api/report/identifiers', params);
  },

  getPages: function (params) {
    return api('GET', '/api/report-type/:report_type/pages', params);
  },

  getRulesetTarget: function (params) {
    return api('GET', '/api/report-type/:report_type/ruleset-target', params);
  },

  getReportExportFormats: function (params) {
    return api('GET', '/api/report-type/:report_type/template', params);
  },

  uploadReport: function (params) {
    return api('POST', '/api/report-type/:report_type/upload', params, {}, {_isFileUpload: true});
  },

  uploadBulkReport: function (params) {
    return api(
      'POST',
      '/api/report/data_point_bulk_upload?success=true',
      params,
      {},
      {_isFileUpload: true, _FileUploadKeys: ['report_metadata', 'data_points']}
    );
  },

  getDataExamples: function (params) {
    return api('GET', '/api/report-type/:report_type/:node/product-examples', params);
  },

  getLogic: function (params) {
    return api('GET', '/api/report-type/:report_type/:node/requirements', params);
  },

  getAnalysis: function (params) {
    return api('POST', '/api/report-type/:report_type/:node/requirements', params);
  },

  getReportTypeHeader: function (params) {
    return api('GET', '/api/report-type/:report_type/header', params, {}, {_suppressNotify: true});
  },

  // REPORTS

  exportReports: function (params) {
    return api('GET', '/api/reports/export/:export_format', params, {}, {_isFileDownload: !params.async_job});
  },

  getReportsInfo: function (params) {
    return api('GET', '/api/reports/info/:property', params);
  },

  // ROLES

  getRoles: function (params) {
    return api(
      'GET',
      '/api/role',
      params,
      params.limit === 0 ? {cache: store.state.system.allRoles, action: 'system/setAllRoles'} : undefined
    );
  },

  createRole: function (params) {
    return api('POST', '/api/role', params);
  },

  roleExists: function (params) {
    return api('GET', '/api/role/exists/:rolename', params);
  },

  deleteRole: function (params) {
    return api('DELETE', '/api/role/:role_id', params);
  },

  updateRole: function (params) {
    return api('PUT', '/api/role/:role_id', params);
  },

  // RULESET

  getRulesets: function (params) {
    return api('GET', '/api/ruleset/v2', params);
  },

  getRuleset: function (params) {
    return api('GET', '/api/ruleset/:id', params);
  },

  updateRuleset: function (params) {
    return api('PUT', '/api/ruleset/:id', params);
  },

  createRuleset: function (params) {
    return api('POST', '/api/ruleset', params);
  },

  archiveRuleset: function (params) {
    return api('DELETE', '/api/ruleset/:id', params);
  },

  updateRulesetApproval: function (params) {
    return api('PUT', '/api/ruleset/:ruleset_id/approvals/:approval_id', params);
  },

  getRulesetEvents: function (params) {
    return api('GET', '/api/ruleset/:id/events', params);
  },

  addRulesetComment: function (params) {
    return api('POST', '/api/ruleset/:id/comments', params);
  },

  getRulesetInfo: function (params) {
    return api('GET', '/api/ruleset/info/:ruleset_property', params);
  },

  // CALCULATIONS

  getCalculations: (params) => {
    return api('POST', '/api/calculation/cr_calculation', params);
  },

  // DECISION TREE

  getAllDecisionTreeAnswers: (params) => {
    return api('GET', '/api/decision-trees/answer', params);
  },

  getDecisionTreeAnswersInfo: (params) => {
    return api('GET', '/api/decision-trees/answer/info/:answer_property', params);
  },

  sendDecisionTreeAnswers: (params) => {
    return api('POST', '/api/decision-trees/answer', params);
  },

  getDecisionTreeAnswers: (params) => {
    return api('GET', '/api/decision-trees/answer/:id', params);
  },

  getDecisionTrees: () => {
    return api('GET', '/api/decision-trees');
  },

  getDecisionTree: (params) => {
    return api('GET', '/api/decision-trees/:decision_tree_id', params);
  },

  // SAML

  getSamlConfig: function (params) {
    return api('GET', '/api/saml/config', params);
  },

  exportSamlConfig: function () {
    return api('GET', '/api/saml/metadata', {}, {}, {_isFileDownload: true, _fileName: 'metadata.xml'});
  },

  exportSPCertificate: function () {
    return api('GET', '/api/saml/metadata/certificate', {}, {}, {_isFileDownload: true, _fileName: 'certificate.pem'});
  },

  updateSamlConfig: function (params) {
    return api('PUT', '/api/saml/config', params);
  },

  getSamlRoleConfig: function () {
    return api('GET', '/api/saml/role_config');
  },

  updateSamlRoleConfig: function (params) {
    return api('PUT', '/api/saml/role_config', params);
  },

  getSamlTeamConfig: function () {
    return api('GET', '/api/saml/team_config');
  },

  updateSamlTeamConfig: function (params) {
    return api('PUT', '/api/saml/team_config', params);
  },

  // SEARCH
  searchConditions: function (params) {
    return api('POST', '/api/search/conditions', params);
  },

  // TAGS

  getAllTags: function () {
    return api('GET', '/api/tags');
  },

  getTag: function (tag_name) {
    return api(
      'GET',
      '/api/tags/tag/' + tag_name,
      {},
      {cache: store.state.logic.tags[tag_name], action: 'logic/setTag'}
    );
  },

  // TEAMS

  getTeams: function (params) {
    return api('GET', '/api/team', params);
  },

  getTeamsSummary: function (params) {
    return api('GET', '/api/summary/team', params, {cache: store.state.system.allTeams, action: 'system/setAllTeams'});
  },

  createTeam: function (params) {
    return api('POST', '/api/team', params, {action: 'system/resetTeams'});
  },

  getTeam: function (params) {
    return api('GET', '/api/team/:team_id', params);
  },

  updateTeam: function (params) {
    return api('PATCH', '/api/team/:team_id', params, {action: 'system/resetTeams'});
  },

  enableTeam: function (params) {
    return api('PUT', '/api/team/enabled', params, {action: 'system/resetSystemConfig'});
  },

  // TEMPLATE-CONFIG

  getReportTemplateConfigs: function (params) {
    return api('GET', '/api/template-config', params);
  },

  createReportTemplateConfig: function (params) {
    return api('POST', '/api/template-config/:report_type', params);
  },

  getReportTemplateConfig: function (params) {
    return api('GET', '/api/template-config/:template_id', params);
  },

  archiveReportTemplateConfig: function (params) {
    return api('DELETE', '/api/template-config/:template_id', params);
  },

  updateReportTemplateConfig: function (params) {
    return api('PATCH', '/api/template-config/:template_id', params);
  },

  // TEMPLATE

  getReportTemplates: function (params) {
    return api('GET', '/api/templates', params);
  },

  // USER

  getUsers: function (params) {
    return api(
      'GET',
      '/api/user',
      params,
      params.limit === 0 && !params.status !== 'disabled'
        ? {cache: store.state.system.allUsers, action: 'system/setAllUsers'}
        : undefined
    );
  },

  createUser: function (params) {
    return api('POST', '/api/user', params, {action: 'system/resetAllUsers'});
  },

  getCurrentUser: function (action = 'user/login') {
    return api('GET', '/api/user/current', {}, {action: action});
  },

  updateUser: function (params) {
    return api('PUT', '/api/user/:user_id', params, {action: 'system/resetAllUsers'});
  },

  updateCurrentUser: function (params) {
    return api('PUT', '/api/user/current', params, {action: 'user/login'});
  },

  getUser: function (params) {
    return api('GET', '/api/user/:user_id', params);
  },

  getCurrentUserAnnouncements: function (params) {
    return api('GET', '/api/user/current/announcements', params);
  },

  getCurrentUserEvents: function (params) {
    return api('GET', '/api/user/current/events', params);
  },

  deleteCurrentUserPhoto: function () {
    return api('DELETE', '/api/user/current/photo');
  },

  getCurrentUserNotifications: function (params) {
    return api('GET', '/api/user/current/notifications', params, {action: 'user/setNotifications'});
  },

  markUserNotificationsAsRead: function (params) {
    return api('PUT', '/api/user/current/notifications', params);
  },

  updateCurrentUserPhoto: function (params) {
    return api('PUT', '/api/user/current/photo', params, {action: 'user/setCurrent'}, {_isFileUpload: true});
  },

  updateCurrentUserSettings: function (params) {
    return api('PUT', '/api/user/current/settings', params);
  },

  getCurrentUserTodos: function (params) {
    return api('GET', '/api/user/current/todos', params, {action: 'user/setTodos'});
  },

  userExists: function (params) {
    return api('GET', '/api/user/exists/:username', params);
  },

  getUserEvents: function (params) {
    return api('GET', '/api/user/:user_id/events', params);
  },

  getUserPhoto: function (params) {
    return api('GET', '/api/user/:user_id/photo', params);
  },

  getSessionExpiration: function () {
    return api('GET', '/api/user/current/session_expires', {}, {action: 'user/setExpiration'});
  },

  // USER QUERY
  getUserQueries: function () {
    return api('GET', '/api/user-query');
  },

  createUserQuery: function (params) {
    return api('POST', '/api/user-query', params);
  },

  deleteUserQuery: function (params) {
    return api('DELETE', '/api/user-query/:query_id', params);
  },

  // API TOKENS

  getUserApiToken: function () {
    return api('GET', '/api/user/current/api_token');
  },

  createUserApiToken(params) {
    return api('POST', '/api/user/current/api_token', params);
  },

  updateUserApiToken(params) {
    return api('PUT', '/api/user/current/api_token', params);
  },

  deleteUserApiToken(params) {
    return api('DELETE', '/api/user/current/api_token?token_id=' + params.token_id);
  },

  getApiTokens: function () {
    return api('GET', '/api/api_token');
  },

  deleteApiToken(params) {
    return api('DELETE', '/api/api_token' + params);
  },

  // VALIDATION RULE

  getValidationRule(params) {
    return api('GET', '/api/validation-rule', params);
  },

  createValidationRule(params) {
    return api('POST', '/api/validation-rule', params);
  },

  getCrossReportTypes(params) {
    return api('GET', '/api/validation-rule/:report_id/cross_validation_report_types', params);
  },

  deleteValidationRule(id) {
    return api('DELETE', '/api/validation-rule/:id', {id: id});
  },

  getValidationRuleById(id) {
    return api('GET', '/api/validation-rule/:id', {id: id});
  },

  putValidationRule(params) {
    return api('PUT', '/api/validation-rule/:rule_id', params);
  },

  getValidationRuleEvents(id) {
    return api('GET', '/api/validation-rule/:id/events', {id: id});
  },

  // WATCHDOG

  // STATUS

  getRunningCalculators() {
    return api('GET', '/api/watchdog/running_calculators', {}, {action: 'system/setRunningCalculators'});
  },

  getServicesStatus() {
    return api('GET', '/api/watchdog/status', {}, {action: 'system/setRunningContainers'});
  },

  getRangeStats(params) {
    return api('GET', '/api/watchdog/status/stats', params);
  },

  getSystemVersion() {
    return api('GET', '/api/watchdog/version');
  },

  downloadValidationFailures(params) {
    return api('POST', '/api/report/validate/rule', params);
  },

  getReportConfig() {
    return api(
      'GET',
      '/api/watchdog/config',
      {},
      {cache: store.state.report.reportsConfig, action: 'report/setReportConfig'}
    );
  },

  // CHANGELOG
  getChangelog() {
    return api(
      'GET',
      'https://updates.suade.org/changev3',
      {},
      {
        action: 'system/setChangelog',
        cache: store.state.system.changelog,
        cacheTimeout: 2 * 60 * 60 * 1000,
      },
      {_suppressNotify: true}
    );
  },

  authenticateStarsEndpoint(params) {
    return api('GET', '/api/stars/sign', params);
  },

  fetchStarsClientVersions(signature, instanceId) {
    return api(
      'POST',
      instanceId === 'test'
        ? 'http://localhost:9090/stars/api/client/version'
        : 'https://updates.suade.org/stars/version',
      {signature},
      {
        action: 'system/setStarsClientVersions',
        cache: store.state.system.starsClientVersions,
        cacheTimeout: 2 * 60 * 60 * 1000,
      },
      {_suppressNotify: true}
    ).catch((error) => {
      console.warn('Failed to fetch versions', error);
      return error;
    });
  },

  fetchStarsClientBenchmarks(signature, instanceId) {
    return api(
      'POST',
      instanceId === 'test'
        ? `http://localhost:9090/stars/api/client/benchmark${instanceId && '?codename=' + instanceId}`
        : `https://updates.suade.org/stars/benchmark${instanceId && '?codename=' + instanceId}`,
      {signature},
      {
        action: 'system/setStarsClientBenchmarks',
        cache: store.state.system.starsClientBenchmarks,
        cacheTimeout: 2 * 60 * 60 * 1000,
      },
      {_suppressNotify: true}
    ).catch((error) => {
      console.warn('Failed to fetch benchmarks', error);
      return error;
    });
  },

  getInstanceId() {
    return api('GET', '/api/debug/instance_id', {}, {action: 'system/setSystemId'});
  },

  // CUSTOM REQUEST

  customRequest: function ({method = 'GET', url = '', params = {}, cache = {}, options = {}}) {
    return api(method.toUpperCase(), url, params, cache, options);
  },
};
