export default {
  name: 'cache',
  namespaced: true,
  state: {},
  mutations: {
    setCache: function (state, payload) {
      state[payload.key] = {timestamp: Date.now()};
    },
  },
  actions: {
    setCache: function (context, payload) {
      context.commit('setCache', payload);
    },
  },
};
