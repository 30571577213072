import {createStore} from 'vuex';

import data from './stores/data';
import report from './stores/report';
import system from './stores/system';
import user from './stores/user';
import logic from './stores/logic';
import dataIngestion from './stores/dataIngestion';
import dataCollection from './stores/dataCollection';
import tutorial from './stores/tutorial';
import cache from './stores/cache';

export default createStore({
  modules: {
    data,
    report,
    system,
    user,
    logic,
    dataIngestion,
    dataCollection,
    tutorial,
    cache,
  },
});
