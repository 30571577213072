import {STime} from '@veasel/core/time';

export default {
  computed: {
    monthDays: function () {
      const monthDays = [];

      const day = this.firstMonthDay.clone();

      while (day.isBefore(this.lastMonthDay)) {
        monthDays.push({
          date: day.clone(),
          isInCurrentMonth: day.month === this.currentMonth,
          isInWeekend: [5, 6].includes(day.getISODayOfWeek()),
          isToday: day.format('dateNumeric') === new STime().format('dateNumeric'),
        });
        day.add(1, 'days');
      }

      this.emitDayRangeEvent();
      return monthDays;
    },

    monthlyWeekDayLabels: function () {
      return this.monthDays.slice(0, 7).map((d) => d.date.format('weekday-short').toUpperCase());
    },

    weekNumbers: function () {
      const weekNumbers = [
        this.monthDays[3].date.getISOWeekOfYear(),
        this.monthDays[10].date.getISOWeekOfYear(),
        this.monthDays[17].date.getISOWeekOfYear(),
        this.monthDays[24].date.getISOWeekOfYear(),
      ];

      if (this.monthDays.length >= 35) {
        weekNumbers.push(this.monthDays[31].date.getISOWeekOfYear());
      }

      if (this.monthDays.length === 42) {
        weekNumbers.push(this.monthDays[38].date.getISOWeekOfYear());
      }

      return weekNumbers;
    },

    month: function () {
      return this.monthDays[6].date.format('month-short');
    },

    year: function () {
      return this.monthDays[6].date.year;
    },

    currentMonth: function () {
      return this.internalDatetimeFocused.month;
    },

    // first day of the first week of the month
    firstMonthDay: function () {
      // isoWeek = Sunday is the last day of week
      return this.internalDatetimeFocused.startOf('month').startOf('week');
    },

    // last day of the last week of the month
    lastMonthDay: function () {
      // isoWeek = Sunday is the last day of week

      const day = this.internalDatetimeFocused.endOf('month').endOf('week');

      if (day) {
        return day;
      }

      console.error(
        `[Suade] Monthly | Cannot calculate last day of the month using: ${this.internalDatetimeFocused
          .endOf('month')
          .endOf('week')}`
      );
    },
  },

  methods: {
    switchToWeekView: function (weekDate) {
      this.internalDatetimeFocused = weekDate;
      this.internalView = 'weekly';
    },

    getMonthlyEventsOfTheDay: function (day) {
      const dayDate = day.format('dateNumeric');
      const filteredEvents = [];

      for (let i = 0; i < this.events.length; i++) {
        const event = this.events[i];

        if (
          (event.start && !event.end && event.start.format('dateNumeric') === dayDate) ||
          (event.end &&
            (event.start.format('dateNumeric') === dayDate ||
              event.end.format('dateNumeric') === dayDate ||
              day.isBetween(event.start, event.end, 'day')))
        ) {
          filteredEvents.push(event);
        }
      }

      filteredEvents.sort((a, b) => a.start.valueOf() - b.start.valueOf());
      return filteredEvents;
    },
  },
};
